import { EditFilled, EditOutlined, HomeOutlined, LineChartOutlined, QrcodeOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { UserRole } from './models';
import './styles/App.scss';

// Layouts
const PublicLayout = React.lazy(() => import("./layouts/PublicLayout"));
const DashboardLayout = React.lazy(() => import("./layouts/DashboardLayout"));
const AdminOnly = React.lazy(() => import("./layouts/AdminOnly"));

// Views
const DashboardHome = React.lazy(() => import("./views/DashboardHome"));
const RegistrationPage = React.lazy(() => import("./views/RegistrationPage"));
const LoginPage = React.lazy(() => import("./views/LoginPage"));
const EditPersonalInfo = React.lazy(() => import("./views/EditPersonalInfo"));
const EditPersonalMatchingInfo = React.lazy(() => import("./views/EditPersonalMatchingInfo"));
const EditCandidatesMatchingInfo = React.lazy(() => import("./views/EditCandidatesMatchingInfo"));
const CreateUserRegistrationCode = React.lazy(() => import("./views/CreateUserRegistrationCode"));
const UsersMatchingOverview = React.lazy(() => import("./views/UsersMatching"));
const UserMatchingDetails = React.lazy(() => import("./views/UserMatchingDetails"));

interface RoutingConfigurationItem {
  path?: string;
  element: JSX.Element;
  requiredRole?: UserRole;
  subItems?: RoutingConfigurationItem[];
  menuItemProps?: MenuItemType;
  loadingScreen?: boolean;
}

const generateRoutes = (RoutingConfiguration: RoutingConfigurationItem[]) =>
  RoutingConfiguration.map(({ element: Element, subItems, path, loadingScreen = true }, i) => (
    <Route
      key={i}
      index={!path as any}
      path={path}
      element={loadingScreen ? <React.Suspense fallback={<Spin fullscreen spinning />}>{Element}</React.Suspense> : Element}
    >
      {subItems && generateRoutes(subItems)}
    </Route>
  ));

export const AdminNavigationItems = (userRole: UserRole): RoutingConfigurationItem[] =>
  userRole === UserRole.ADMIN ? [
    {
      path: "admin",
      element: <AdminOnly />,
      requiredRole: UserRole.ADMIN,
      subItems: [
        {
          element: <DashboardHome />
        },
        {
          path: "create-user-code",
          element: <CreateUserRegistrationCode />,
          menuItemProps: {
            key: "admin-create-user-code",
            label: "Benutzercode erstellen",
            icon: <QrcodeOutlined />,
          }
        },
        {
          path: "matching",
          element: <UsersMatchingOverview />,
          menuItemProps: {
            key: "admin-matching-overview",
            label: "Matching",
            icon: <LineChartOutlined />,
          },
          subItems: [
            {
              menuItemProps: {
                key: "admin-matching-overview",
                label: "Übersicht",
              },
              element: <UserMatchingDetails />,
            },
            {
              path: ":selectedUserId",
              element: <UserMatchingDetails />,
            }
          ]
        }
      ],
      menuItemProps: {
        key: "dashboard-admin",
        icon: <SettingOutlined />,
        label: "Admin",
      }
    },
  ] : [];

export const AuthenticationRoutes = (userRole: UserRole): RoutingConfigurationItem[] => [
  {
    element: <LoginPage />,
  },
  {
    path: "register",
    element: <RegistrationPage />,
    menuItemProps: {
      key: "public-registration",
      label: "Registrieren",
    }
  },
  {
    path: "login",
    element: <LoginPage />,
    menuItemProps: {
      key: "public-login",
      label: "Anmelden",
    }
  },
];

export const SidebarNavigationItems = (userRole: UserRole): RoutingConfigurationItem[] => [
  {
    element: <Navigate to={"home"} />,
  },
  {
    path: "home",
    element: <DashboardHome />,
    menuItemProps: {
      key: "dashboard-home",
      icon: <HomeOutlined />,
      label: "Übersicht",
    }
  },
  {
    path: "profile",
    element: <EditPersonalInfo />,
    menuItemProps: {
      key: "profile-personal",
      icon: <UserOutlined />,
      label: "Persönliche Informationen",
    },
  },
  {
    path: "matching",
    element: <EditPersonalMatchingInfo />,
    menuItemProps: {
      key: "profile-matching",
      icon: <EditOutlined />,
      label: "Matching Informationen",
    }
  },
  {
    path: "candidate-preferences",
    element: <EditCandidatesMatchingInfo />,
    menuItemProps: {
      key: "matching-preferences",
      icon: <LineChartOutlined />,
      label: "Suchprofil",
    }
  },
  ...AdminNavigationItems(userRole)
];

export const NavigationItems = (userRole: UserRole): RoutingConfigurationItem[] => [
  {
    path: "auth",
    element: <PublicLayout />,
    subItems: [
      ...AuthenticationRoutes(userRole)
    ],
    menuItemProps: {
      key: "authentication-layout",
      label: "Authentifizierung",
    }
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    subItems: [
      ...SidebarNavigationItems(userRole)
    ],
    menuItemProps: {
      key: "dashboard",
      label: "Dashboard",
    }
  },
];

export const getNavItemsbyKeypath = (keyPath: string[], SubjectNavigationItems: RoutingConfigurationItem[]): RoutingConfigurationItem[] => {
  const firstItem = SubjectNavigationItems.find(item => item.menuItemProps && item.menuItemProps.key === keyPath[0]);

  if (!firstItem) return [];

  return keyPath.slice(1).reduce<RoutingConfigurationItem[]>((currentItems, part) => {
    const matchingItem = currentItems[currentItems.length - 1].subItems?.find(item => item.menuItemProps && item.menuItemProps.key === part);
    return matchingItem ? [...currentItems, matchingItem] : currentItems;
  }, [firstItem]);
};

export const useBreadcrumbItems = (path: string, userRole?: UserRole): RoutingConfigurationItem[] => {
  const { user } = useAuth();
  return getBreadcrumbItemsByRoutingConfig(NavigationItems(userRole ?? user?.role ?? UserRole.USER), path);
};

export const getBreadcrumbItemsByRoutingConfig = (RoutingConfig: RoutingConfigurationItem[], path: string): RoutingConfigurationItem[] => {
  const items: RoutingConfigurationItem[] = [];

  const findItem = (config: RoutingConfigurationItem[], path: string) => {
    for (const item of config) {
      if (item.path && path.includes(item.path)) {
        items.push(item);
        if (item.subItems) {
          findItem(item.subItems, path);
        }
        break;
      }
    }
  };

  findItem(RoutingConfig, path);

  return items;
};

const App: FC = () => {
  const { user } = useAuth();
  return (
    <Routes>{generateRoutes(NavigationItems(user?.role ?? UserRole.USER))}</Routes>
  );
};

export default App;
