import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, ValidationError } from "../models";
import useAxios from "axios-hooks";
import { message } from "antd";

export const JWT_LOCALSTORAGE_KEY = "token";
export const TOKEN_RENEWAL_LOADING_MESSAGE_KEY = "TOKEN_RENEWAL_LOADING_MESSAGE";


export interface IAuthContext {
    token: string | null;
    setToken: (newToken: string | null) => void;
    logout: () => void,
    loading: boolean;
    user: User | null;
    renewToken: () => void;
}

export const AuthContext = createContext<IAuthContext>({
    token: null,
    setToken: () => { },
    logout: () => { },
    loading: true,
    user: null,
    renewToken: () => { }
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [{ loading: tokenRenewalLoading }, requestNewToken] = useAxios(
        {
            url: `/auth/renew-token`,
            method: 'POST'
        },
        { manual: true }
    );

    const renewToken = () => {
        message.loading({ content: "Nutzerdaten werden aktualisiert...", duration: 0, key: TOKEN_RENEWAL_LOADING_MESSAGE_KEY }, 0);
        requestNewToken()
            .then((response) => {
                handleTokenChange(response.data?.token);
                message.success("Nutzerdaten erfolgreich aktualisiert!", 5);
            })
            .catch((error) => {
                if (error.response?.status === 400 && Array.isArray(error.response?.data?.error)) {
                    const validationErrors: ValidationError[] = error.response.data.error;
                    validationErrors.forEach((validationError) => message.error(`${validationError.path}: ${validationError.msg}`, 5));
                } else if (error.response?.data?.error) {
                    message.error(error.response?.data?.error, 5)
                } else {
                    message.error(error.message, 5)
                }
            })
            .finally(() => {
                message.destroy(TOKEN_RENEWAL_LOADING_MESSAGE_KEY);
            });
    };

    const user: User = useMemo(() => {
        if (!token) return null;
        const [, payload] = token.split(".");
        const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );

        const payloadData = JSON.parse(jsonPayload);
        return {
            ...payloadData.user,
            birthday: payloadData.user.birthday ? new Date(payloadData.user.birthday) : undefined,
            rawBirthday: payloadData.user.birthday,
            iat: payloadData.iat,
        };
    }, [token]);

    useEffect(() => {
        const storedToken = localStorage.getItem(JWT_LOCALSTORAGE_KEY);
        setToken(storedToken);
        setLoading(false);
    }, []);

    const handleTokenChange = (newToken: string | null) => {
        if (newToken) {
            localStorage.setItem(JWT_LOCALSTORAGE_KEY, newToken);

        } else {
            localStorage.removeItem(JWT_LOCALSTORAGE_KEY);
        }
        setToken(newToken);
    };

    const handleLogout = () => {
        handleTokenChange(null);
        navigate("/auth/login");
    };

    return (
        <AuthContext.Provider value={{ token, user, setToken: handleTokenChange, logout: handleLogout, loading, renewToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};