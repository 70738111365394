export interface ValidationError {
    location: string;
    msg: string;
    path: string;
    type: string;
    value: string;
}

export interface User {
    _id: string;

    email: string;

    firstname: string;
    lastname: string;
    telephone: string;
    birthday: Date;
    birthplace?: string;
    gender: "M" | "W";

    role?: UserRole;
}

export enum UserRole {
    USER = 1,
    ADMIN = 2,
}