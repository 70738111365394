import { App as AntdApp, ConfigProvider, ThemeConfig } from 'antd';
import deDE from 'antd/locale/de_DE';
import dayjs from 'dayjs';
import 'dayjs/locale/de-at';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import { AuthProvider, JWT_LOCALSTORAGE_KEY } from './context/AuthContext';
import axios from 'axios';

dayjs.locale('de-at');
moment.locale("de");

axios.interceptors.request.use(request => {
  request.baseURL = process.env.REACT_APP_BACKEND_URL;
  request.headers['Content-Type'] = 'application/json';
  request.headers['Authorization'] = `Bearer ${localStorage.getItem(JWT_LOCALSTORAGE_KEY)}`;
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Theme: ThemeConfig = {
  token: {
    colorPrimary: "#16A085",
  },
  components: {
    Layout: {
      siderBg: "#11856e"
    },
    Menu: {
      colorPrimary: "#fff",
      itemBg: "#16A085",
      subMenuItemBg: "#0f7561",
      colorText: "#fff",
      itemSelectedBg: "#07332a",
      itemMarginInline: 0,
      itemBorderRadius: 0,
      itemMarginBlock: 0,
    },
  },
};

root.render(
  <BrowserRouter>
    <AntdApp>
      <ConfigProvider
        locale={deDE}
        theme={Theme}
      >
        <AuthProvider>
          <App />
        </AuthProvider>
      </ConfigProvider>
    </AntdApp>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
